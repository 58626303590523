(function(global) {
  window.addEventListener("loadWidgetConfig", function() {
    const { widgetId, widgetEnv } = global.wvusWidgetParams;
    const widgetConfig = global.wvusDonateWidgetConfig[widgetId];
    const { campaignId } = widgetConfig;
    const gtmPreview = ["qa","qa1","qa2","dev","local"].includes(widgetEnv) ? "env-5" : ["uat"].includes(widgetEnv) ? "env-6" : "env-7";
    const authKey = ["qa","qa1","qa2","dev","local"].includes(widgetEnv) ? 'EPw0MRlfxww2OhJLIzIijA' : ["uat"].includes(widgetEnv) ?  'Yrun1Z0AVKL0EawbzfULSg' : 'z_BBVCAVGzAqGVi0DUM1BQ';
    const gtmTagId = 'GTM-557BVL';

    /**************** 
    * Data Layer
    ****************/
    global.dataLayer = [];
    global.dataLayer.push({
        'page_name'    : global.document.title,
        'cd10_campaign' : campaignId,
        'web_property': "donate-widget"
    });

    /**************** 
    * GTM Script
    ****************/
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ `&gtm_auth=${authKey}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', gtmTagId);
  });
})(window);
